import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { gethelpLinks } from "../../../api/menuApi";

const useStyles = makeStyles((theme) => ({
  listItemLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    '&:hover': {
      textDecoration: "underline",
    }
  },
  secondaryText: {
    textDecoration: "none !important",
  }
}));

function Help() {
  const [helpLinks, setHelpLinks] = useState([]);
  const classes = useStyles();
  const quotaManagementEmail = process.env.QUOTA_MANAGEMENT_EMAIL || "suppquot@microsoft.com";
  const isfed = process.env.ISFED==="true";
  const icm_details = "</br>(Make sure to update the following details in the ICM </br> &nbsp &nbsp <b>a.	Title</b> – Edit and add a relevant title. </br> &nbsp &nbsp <b>b.	Severity</b> -  Select <b>Severity 4</b> </br> &nbsp &nbsp <b>c.	Environment</b>: Select <b>PROD</b> </br> &nbsp &nbsp <b>d.	Cloud Instance</b> - Select <b>PUBLIC</b> <br> Please add the necessary details and screenshots to help the support team understand the issue better.)";
  const icm_fed_details = "</br>For help with UBI adjustment request, please reference this Deck ";

  React.useEffect(() => {
    gethelpLinks().then(items => setHelpLinks(items), () => setHelpLinks([]));
  }, []);

  function renderHelpLink() {
    if (!helpLinks || !helpLinks.length) {
      return <></>;
    }
    return (
      <>
        <section id="help">
          <h1>Help Links</h1>
        </section>
        <br></br>
        <h2>Training Resources</h2>
        {helpLinks.map((element, index) => (
          <div>
            <List>
              <ListItem button>
                <ListItemText primary={<span><a href={element.link} className={classes.listItemLink} rel="noopener noreferrer" target="_blank">{index + 1}. {element.text}</a><span className={classes.secondaryText}> - {element.description}</span></span>} />
              </ListItem>
            </List>
          </div>
        ))}
        <br></br>
      </>
    );
  }
  return (
    <React.Fragment>
      {renderHelpLink()}
      <h2>Helpdesk & Support</h2>
      {!isfed && <List>
        <ListItem button >
          <ListItemText primary={<span><a href={'https://aka.ms/wwichelp'} className={classes.listItemLink} rel="noopener noreferrer" target="_blank"> {'1. Contact WWIC help for Seller Quota and Crediting related issues.'}</a></span>} />
        </ListItem>
        <ListItem button>
          <ListItemText primary={<span><a href={'https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=9f82S3'} className={classes.listItemLink} rel="noopener noreferrer" target="_blank"> {'2. Create ICM for Mint Studio related issues.'}</a>
            <div
              style={{ alignContent: "space-between", display: "inline" }}
              dangerouslySetInnerHTML={{
                __html: icm_details
              }}>
            </div></span>} />
        </ListItem>
      </List>}

      {isfed && <List>
        <ListItem button>
          <ListItemText primary={<span>
            <div
              style={{ alignContent: "space-between", display: "inline" }}
              dangerouslySetInnerHTML={{
                __html: icm_fed_details
              }}>
            </div>
            <a href={'https://microsoft.sharepoint.com/:p:/t/IncentiveCompensationGuide/EVS1nGhJH31IkPFiipNUrtoBgLQKUPi4czhwHzFuxDtZOA?e=6qzu8Q'} className={classes.listItemLink} rel="noopener noreferrer" target="_blank"> {'<How to log a UBI Adjustment (UBI Managers)-Federal.pptx (sharepoint.com)>'}</a>
            <div
              style={{ alignContent: "space-between", display: "inline" }}
              dangerouslySetInnerHTML={{
                __html: " in CorpNet.</br> For additional support, please email svc-fedicsup@microsoftfederal.com"
              }}>
            </div>
            </span>} />
        </ListItem>
      </List>}
    </React.Fragment>
  );
}

export default Help;